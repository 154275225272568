<template>
  <div>
    <loading-overlay :active="LoadingGlobal" :is-full-page="true" loader="bars" />
    <CCollapse :show="collapse">
      <CCard>
        <CCardHeader color="dark text-white">
          <CRow>
            <CCol sm="8" class="d-flex ">{{titulo}}</CCol>
             <CCol sm="4" class="d-flex justify-content-end">
                <CButton size="sm" 
                @click="tablaCollapse = !tablaCollapse" 
                  v-c-tooltip="{
                    content: $t('label.dataTable'),
                    placement: 'top-end'
                }"
                  >
                  {{ cambiaTexto }}
                <CIcon :name="cambiaIcono"/>
              </CButton>
              </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="mb-3">
              <CRow>
                <CCol sm="3" class="text-right mt-2 pr-1">
                  <label><b class="text-danger"> * </b> {{ $t('label.activity')+' '+('(ES)') }}</label>
                </CCol>
                <CCol sm="7"><!--   -->
                  <CInput                              
                    maxlength="250"
                    v-model.trim="$v.TpClientNameEs.$model"
                    :is-valid="hasError($v.TpClientNameEs)"               
                    :invalid-feedback="errorMessage($v.TpClientNameEs)" 
                    :placeholder="$t('label.activityName')+' '+('(ES)')"          
                  />
                </CCol>
                <CCol sm="3" class="text-right mt-2 pr-1">
                  <label><b class="text-danger"> * </b> {{ $t('label.activity')+' '+('(EN)') }} </label>
                </CCol>
                <CCol sm="7"><!--   -->
                  <CInput                              
                    maxlength="250"
                    v-model.trim="$v.TpClientNameEn.$model"
                    :is-valid="hasError($v.TpClientNameEn)"               
                    :invalid-feedback="errorMessage($v.TpClientNameEn)" 
                    :placeholder="$t('label.activityName')+' '+('(EN)')"          
                  />
                </CCol>
                <CCol sm="2" >                
                    <CButton color="add" :disabled="isSubmit" size="sm" class="mr-1"  
                      @click="verifyStatus()"  
                      v-c-tooltip="{
                          content: $t('label.add'),
                          placement: 'top-end'
                      }"
                      >
                      <CIcon name="checkAlt"/>
                    </CButton>
                    <CButton color="wipe" :disabled="isSubmit" size="sm" class="mr-1"  
                      @click="refreshComponent()"  
                      v-c-tooltip="{
                          content: $t('label.clearFields'),
                          placement: 'top-end'
                      }"
                      >
                      <CIcon name="cil-brush-alt"/>
                    </CButton>
                </CCol>
                <CCol sm="3" v-if="isEdit" class="text-right mt-2 pr-1">
                  <label><b class="text-danger"> * </b> {{ $t('label.status') }} </label>
                </CCol>
                <CCol sm="7" v-if="isEdit" ><!-- status -->
                  <CSelect
                    v-uppercase
                    v-model="Status"
                    :value.sync="Status"
                    :is-valid="statusSelectColor"
                    :options="selectOptions"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CCollapse :show="tablaCollapse" class="mt-2">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="items"
              :fields="fields"
              :loading="Loading"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="5"
              hover
              small
              sorter
              pagination
            >
              <template #loading>
                <loading/>
              </template>
              <template #Status="{item}">
                <td class="center-cell">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{item}">
              <td class="center-cell">
                <CButton color="edit" class="mr-1" size="sm" 
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.activity'),
                    placement: 'top-end'
                  }"
                  @click="editItem(item)" 
                >
                  <CIcon name="pencil" />
                </CButton>
              </td>
            </template>
            </dataTableExtended>
          </CCollapse>
        </CCardBody>
      </CCard>
    </CCollapse>
  </div>
</template>

<script>
import ActividadValidations from '@/_validations/cliente/actividadValidations';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';

function fields(){ 
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;', },
    { key: 'TpClientNameEs', label: this.$t('label.activity')+' '+('(ES)'), _style:'width:45%', _classes: 'text-center center-cell' },
    { key: 'TpClientNameEn', label: this.$t('label.activity')+' '+('(EN)'), _style:'width:45%', _classes: 'text-center center-cell' },
    { key: 'Usuario', label: this.$t('label.user'), _style:'width:15%;', _classes: 'text-center center-cell' },
    { key: 'Fecha', label: this.$t('label.date'), _style: 'width: 15%; text-align:center;', _classes:'text-center center-cell'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 15%;', _classes:'text-center center-cell'},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style:'width:5%; min-width:45px;',
      _classes: 'text-center center-cell'
    }
  ]
}

function data() {
  return {
    //MODELO
    TpClientId: '',
    TpClientNameEn: '',
    TpClientNameEs: '',
    UserId: '',
    Status: 1,
    FgActTpClient: 1,

    //VARIABLES
    isSubmit: false, 
    isEdit: false,
    tablaCollapse: false,
    Loading: false,
    LoadingGlobal: false,
    titulo: this.$t('label.nueva')+' '+this.$t('label.activity'),
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: this.$t('label.name'),
    items: [],
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
  }    
}

function editItem(item) {
  let _lang = this.$i18n.locale;

  this.isEdit = true;
  this.TpClientId = item.TpClientId;
  this.TpClientNameEs = item.TpClientNameEs;
  this.TpClientNameEn = item.TpClientNameEn;
  this.Status = item.FgActTpClient;
  this.FgActTpClient = item.FgActTpClient;

  this.titulo = _lang=='en' ? this.$t('label.edit')+' '+this.$t('label.activity')+': '+item.TpClientNameEn :
                              this.$t('label.edit')+' '+this.$t('label.activity')+': '+item.TpClientNameEs
  this.$v.$touch();
}

function verifyStatus() {
  if(this.Status == !this.FgActTpClient){
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')+'?'}`,
    })).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });
  }else{
    this.submit();
  }
}

function refreshComponent() {
  this.TpClientNameEn = '';
  this.TpClientNameEs = '';
  this.TpClientId   = '';
  this.Status = 1;
  this.FgActTpClient = 1;
  this.isEdit = false;
  this.titulo = this.$t('label.nueva')+' '+this.$t('label.activity');
  this.$nextTick(() => { this.$v.$reset() })
}

//crea y edita las actividades
function submit() {
  try {
    this.LoadingGlobal = true;
    this.isSubmit = true; 
    this.$v.$touch();

    if (this.$v.$error) {
      this.LoadingGlobal = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let res = [];
    let TpClientJson = [];
    let metodo = '';
    let ruta = '';
  
  if (this.TpClientId=="") {
    TpClientJson = {
      TpClientNameEs: this.TpClientNameEs,
      TpClientNameEn: this.TpClientNameEn,
      UserId: this.user.UserId,
    };
    metodo="POST";
    ruta="TpClient-insert";
  } else {
    TpClientJson = {
      TpClientId: this.TpClientId,
      TpClientNameEs: this.TpClientNameEs,
      TpClientNameEn: this.TpClientNameEn,
      Status: this.Status,
      UserId: this.user.UserId,
    };
    metodo="PUT";
    ruta="TpClient-update";
  }

  this.$http.ejecutar(metodo, ruta, TpClientJson, { root: 'TpClientJson' })
  .then(response => {
    res = [...response.data.data];
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.refreshComponent();
    this.getActivityList();
    this.LoadingGlobal= false;
    this.$emit('child-refresh',true);
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.LoadingGlobal= false;
  }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function getActivityList() {
  let listado = Array;
  this.Loading=true;
  this.items = [];
  this.items.length = 0;

  this.$http.get("TpClient-list", {})
  .then(response => {
    listado = [...response.data.data];

    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: listado.Nro,
      TpClientNameEn: listado.TpClientNameEn,
      TpClientNameEs: listado.TpClientNameEs,
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      TpClientId: listado.TpClientId,
      FgActTpClient: listado.FgActTpClient ? 1 : 0,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading= false;
  });
}
function getTooltip(status) {
  switch (status) {
    case 'ACTIVO': 
      return {
        content: this.$t('label.inactivateActivity'), //'Inactivar actividad',
        placement: 'top-end'
      };
    case 'INACTIVO':
      return {
        content:  this.$t('label.activateActivity'), //'Activar actividad',
        placement: 'top-end'
      };
  }
}
//COMPUTED
function cambiaIcono() {
  return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}

export default {
  name:'actividad-collapse',
  mixins: [General, ModalMixin],
  data,
  props: {
    collapse: null,
    cerrarModalCollapse: null,
  },
  directives: UpperCase,
  validations: ActividadValidations,
  methods:{
    submit,
    refreshComponent,
    verifyStatus,
    getActivityList,
    editItem,
    getTooltip,
  },
  watch: {
    tablaCollapse: function() {
      if (this.tablaCollapse) {
        this.getActivityList();
        this.refreshComponent();
      }
    },
    collapse: function() {
      if (this.collapse) {
        this.tablaCollapse= false,
        this.refreshComponent();
      }
    }
  },
  computed:{
    cambiaIcono,
    cambiaTexto,
    
    fields,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>